import React, { useContext, useMemo } from 'react';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _isFunction from 'lodash/isFunction';

import { If } from 'utils';
import { ShopperGroupSelector, StickyButtons, UiKit } from 'components';

export default function Actions({
  formData = null,
  initialFormData = null,
  isFetching = false,
  isProcessing = false,
  showDeleteModal = false,
  homeRoute = '',
  onDelete = () => {},
  onOpenDeleteModal = () => {},
  onCloseDeleteModal = () => {},
  onResetForm = null
}) {
  const { selectedOption, onSubmitNew, hasAccess, isConfigurable } = useContext(
    ShopperGroupSelector.Context
  );

  const groupName = _get(selectedOption, 'label', '');
  const isConfigured = _get(selectedOption, 'is_configured', false);

  const isDirty = useMemo(() => {
    return _isEqual(formData, initialFormData);
  }, [formData, initialFormData]);

  const disableSaveButton = isDirty && isConfigured;

  return (
    <>
      <StickyButtons
        alwaysEdit
        data={formData}
        initialData={initialFormData}
        helptext={
          <If test={hasAccess && isConfigurable && groupName}>
            Currently Configuring - <b>{groupName}</b>
          </If>
        }
      >
        {_isFunction(onResetForm) && (
          <UiKit.Button
            customType="inline"
            onClick={onResetForm}
            disabled={isFetching || isProcessing}
          >
            Reset
          </UiKit.Button>
        )}

        {isConfigured && (
          <UiKit.Button
            customType="danger"
            onClick={onOpenDeleteModal}
            disabled={isFetching || isProcessing}
          >
            Delete
          </UiKit.Button>
        )}
        <UiKit.Button
          customType="success"
          onClick={onSubmitNew}
          disabled={disableSaveButton || isFetching}
          processing={isProcessing}
        >
          Save Changes
        </UiKit.Button>
      </StickyButtons>
      <UiKit.Modal
        isOpen={showDeleteModal}
        title={'Delete shopper group'}
        onClose={onCloseDeleteModal}
        onConfirm={() => onDelete(onCloseDeleteModal)}
        isProcessing={isProcessing}
        confirmBtnType="danger"
        confirmBtnText="Delete group"
        confirmBtnProcessingText="Deleting..."
      >
        <div>
          Are you sure you want to delete configurations for this{' '}
          <b>{groupName}</b> shopper group?
        </div>
      </UiKit.Modal>
    </>
  );
}
