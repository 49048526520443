import _get from 'lodash/get';

import daysTillNow from './daysTillNow';
import sortPermittedStore from './sortPermittedStore';
import transformPermissions from './transformPermissions';

export default function transformConfiguration(config = {}, appType = {}) {
  const isRetexApp = _get(appType, 'retex', true);
  const isSuperuser = _get(config, 'is_superuser', false);
  const installOn = _get(config, 'retailer_installed_at', '');
  const accessToStores = _get(config, 'access_to_stores', []);
  const isAddOnStore = _get(config, 'is_addon_store', false);
  const isTestRetailer = _get(config, 'is_test_retailer', false);
  const displayMenuOptions = _get(config, 'displayed_menu_options', null);
  const showTour = _get(config, 'notifications.user_guiding.enabled', false);
  const storeUniqueName = _get(config, 'storeProperties.store_unique_name', '');
  const showSetupChecklist =
    isRetexApp && isSuperuser && installOn && daysTillNow(installOn) <= 30;

  return {
    showTour,
    installOn,
    isSuperuser,
    isAddOnStore,
    isTestRetailer,
    accessToStores,
    displayMenuOptions,
    showSetupChecklist,
    isTourShown: !showTour,
    syncStatus: _get(config, 'sync_status', null),
    accountInfo: _get(config, 'account_info', {}),
    appLevelAlert: _get(config, 'alert_bar', null),
    accessBlocked: _get(config, 'access_blocked', null),
    storeProperties: _get(config, 'storeProperties', {}),
    isExternalUser: _get(config, 'is_externaluser', false),
    isTermsAccepted: _get(config, 'tos_pp_accepted', true),
    isExchangeAllowed: _get(config, 'is_exchange_allowed', true),
    externalUserAccess: _get(config, 'externaluser_access', null),
    isWarehouseEnabled: _get(config, 'is_warehouse_enabled', false),
    isOnboardingComplete: _get(config, 'onboarding_completed', true),
    isDefaultWarehouseSetup: _get(config, 'is_default_warehouse_set', false),
    permittedStores: sortPermittedStore(accessToStores),
    configuration: transformPermissions(
      displayMenuOptions,
      storeUniqueName,
      isTestRetailer
    )
  };
}
