export default {
  // RETURN RABBIT -----------------------------------------------------------------------
  RR_DOCUMENTATION:
    'https://returnrabbit.notion.site/ReturnRabbit-Public-Wiki-45aac1425b4940dab811d3be7f4b53ad',

  RR_PRIVACY_POLICY:
    'https://www.notion.so/returnrabbit/Privacy-Policy-af6149254faf400790b6083ad1ab08b7',

  RR_TERMS_OF_USE:
    'https://www.notion.so/returnrabbit/Terms-of-service-e7218c9210354b7aa5e676466d23d3da',

  OVERVIEW:
    'https://returnrabbit.notion.site/Overview-Screen-Admin-Portal-Landing-Page-bac9d44d02804a038d2d61c6cdc02971',

  CREATE_SERVICE_REQUEST:
    'https://returnrabbit.notion.site/Creating-a-Service-Request-from-Admin-Portal-3c415cdffb884f818038ca2c8d8ae69f',

  REQUESTS: '',

  ANALYTICS: {
    DASHBOARD:
      'https://returnrabbit.notion.site/Dashboard-49f4fe96884c4c6196f1ffb200fb66e2#ff73fec6d42d4731bdecab2b5e93491a',
    INSIGHTS:
      'https://returnrabbit.notion.site/Insights-e1ddfcb392ec4b178405e6c626e9a98e#effbdc369bd74dfbbd0f74ab55b063ed',
    USER_REPORTS:
      'https://returnrabbit.notion.site/Reporting-Feature-1908f0a434e040f197db2c8b415cf1f1'
  },

  CONFIGURE: {
    // Return Management 👇
    REFUND_METHODS:
      'https://returnrabbit.notion.site/Refund-Methods-2c12251b71a543079bd79ad58f72375f',
    AUTOMATION_RULES:
      'https://returnrabbit.notion.site/Automation-Rules-e86036f66b414ab883cdebe5ba386170',
    FEES_AND_INCENTIVES:
      'https://returnrabbit.notion.site/Fees-Incentives-46f517c7d9a74b408216787abab73f64',
    // EXCHANGE_CREDITS:
    //   "https://returnrabbit.notion.site/Monetary-Exchange-Incentives-bda95b9b22c54fc18fa09a2303ac1ac9",
    REQUEST_REASONS:
      'https://returnrabbit.notion.site/Request-Reasons-f6179b76c95a42bba91aaeb357eb6ae5',
    EXCHANGE_CATALOG:
      'https://returnrabbit.notion.site/Catalog-Exchange-c9e4711dc20346bea0a6149bf17efc74',
    EXCHANGE_RECOMMENDATIONS:
      'https://returnrabbit.notion.site/Exchange-Recommendations-502c05059137465cbd42a74d9b06706b',
    EXCHANGE_INCENTIVES:
      'https://returnrabbit.notion.site/Exchange-Incentives-98a7f8e18e6f4a8a8dad9d2e93399d04',
    PURCHASE_CATALOG: '',
    PURCHASE_RECOMMENDATIONS: '',
    SERVICE_WINDOW: {
      HOME:
        'https://returnrabbit.notion.site/Service-Window-96a614b710f74c37adb164863ba81cb2',
      ORDER_DATE_EXCEPTIONS:
        'https://returnrabbit.notion.site/Order-Date-Service-Window-Sales-da452afde99a4f1c9cb08ea2ed2e33b4',
      PRODUCT_SKU_EXCEPTIONS:
        'https://returnrabbit.notion.site/Product-SKU-based-Service-Windows-2ea69af066b74d0cab9a5ac2d2b0cb69',
      PRODUCT_TYPE_EXCEPTIONS:
        'https://returnrabbit.notion.site/Product-Type-based-Service-Windows-b086400b25a3437a941340370c00bcaf',
      PRODUCT_CATEGORY_EXCEPTIONS:
        'https://returnrabbit.notion.site/Product-Category-based-Service-Windows-10ab719da6b480ebb7d3d19055050393?pvs=4'
    },
    PROMO_MANAGEMENT:
      'https://returnrabbit.notion.site/Promo-Management-e7c20156081d4740b303422182b32a3f',
    EMAIL_ALERTS:
      'https://returnrabbit.notion.site/E-mail-Alerts-bc027542737d425588e2d2c533aa2374',
    TAG_BASED_RULES:
      'https://returnrabbit.notion.site/Tag-Based-Rules-db896e03c2514d82ae1753a2967a8859',
    PRODUCT_TAGS:
      'https://returnrabbit.notion.site/Product-Tags-5454f945a1094f1d92f586463d6d212c',
    SHOPPER_GROUPS:
      'https://returnrabbit.notion.site/NEW-Shopper-Groups-e92b40c92e9344fb8d20bcfce8a75bb3',
    PURCHASES:
      'https://returnrabbit.notion.site/NEW-Purchase-174b719da6b4804a90a0f3ae5e45d087',

    // Shipping 👇
    SHIPPING_METHODS: {
      ROOT:
        'https://returnrabbit.notion.site/Shipping-Methods-4b752559b4314913bfed6f91c545001c',
      IN_STORE_RETURN:
        'https://returnrabbit.notion.site/Shipping-Methods-4b752559b4314913bfed6f91c545001c?pvs=4#74d31d31e2db4746943a3c4ac83993c4'
    },
    SERVICE_FACILITIES:
      'https://returnrabbit.notion.site/Service-Facilities-848be999059748d79feb068bba06778f',
    CARRIERS: '', // TODO: Add the documentation URL
    SERVICE_ZONES:
      'https://returnrabbit.notion.site/Service-Zones-Areas-you-serve-d3312e59d5064502ae50086b918f8f0f',
    SERVICE_LANES:
      'https://returnrabbit.notion.site/Service-Lane-d47b1267c47242ec9a5959268d5645ec',
    BOX_DIMENSIONS:
      'https://returnrabbit.notion.site/Standard-Box-Dimensions-4e9c1fb9f17f46b7ac25913827f19e4a',
    CARRIER_CONFIG:
      'https://returnrabbit.notion.site/Carrier-Config-b4fe277bfd3d4c188602bca4a014be29',
    AGGREGATORS: {
      SHIPENGINE:
        'https://returnrabbit.notion.site/ShipEngine-Setup-82fc4c7cb8764bc5b25f87053022a589',
      SHIPSTATION:
        'https://returnrabbit.notion.site/returnrabbit/ShipStation-Setup-c8e537c07306498baadb790cbb04fe0e'
    },
    ENTERPRISE_SHIPPING:
      'https://returnrabbit.notion.site/NEW-Enterprise-Shipping-eada3154950c4fc692ded1d9804411b4',

    // Shopper Portal
    THEME:
      'https://returnrabbit.notion.site/Theme-configuration-59b454bf9a92400e956b3dc2c5dbc7c5',
    TRACKING_PAGE:
      'https://returnrabbit.notion.site/Shipment-Tracking-Page-4029ed0dd36c40fabcbf538623ef693f',
    EMAIL_TEMPLATES:
      'https://returnrabbit.notion.site/E-mail-Templates-841a9cc48651474fae90d3c50b13537b',
    CUSTOMER_CARE:
      'https://returnrabbit.notion.site/Customer-Care-Info-5f78075711eb463f93ca9a4f948a0696',
    POLICY_POPUP:
      'https://returnrabbit.notion.site/Return-Policy-Pop-up-55876d79cb0343c98e4e3b44b78c7bd9',
    PRIMARY_COLOR:
      'https://returnrabbit.notion.site/Editing-Highlight-Colours-4c08aef1a4604113b6767580d4afe24e',

    // Other Features
    GIFT_RETURNS:
      'https://returnrabbit.notion.site/Gift-Returns-e81fa7d6e8df4e78a1a073e4de2cf35f',
    DISCOUNT_FORWARDING:
      'https://returnrabbit.notion.site/Discount-Forwarding-7644089cbd2940ec90ace9acefbfc865',
    EXCHANGE_BONUS_CASH:
      'https://returnrabbit.notion.site/Bonus-Exchange-Credits-bda95b9b22c54fc18fa09a2303ac1ac9',
    RETURN_PROCESSING_FEE:
      'https://returnrabbit.notion.site/Fees-Incentives-46f517c7d9a74b408216787abab73f64?pvs=4#0b971942a11047fd811701c66ced95b0',
    MULTI_CURRENCY_ORDER:
      'https://returnrabbit.notion.site/Additional-Features-92e358a8cbcd4313a169729af7cfce68?pvs=4#146e7ea0df5f42b9a4d35cd556a8d80a',
    BXGY_PAID_ONLY:
      'https://returnrabbit.notion.site/Additional-Features-92e358a8cbcd4313a169729af7cfce68?pvs=4#cce9565b1ce841728f3f177d42e921c3',
    HONOR_INVENTORY_POLICY:
      'https://returnrabbit.notion.site/Additional-Features-92e358a8cbcd4313a169729af7cfce68?pvs=4#72ecee265b3e490cb6093a87503f2624',
    HONOR_ORIGINAL_PRICE_ON_VARIANT_EXCHANGE:
      'https://returnrabbit.notion.site/Additional-Features-92e358a8cbcd4313a169729af7cfce68?pvs=4#0a2698fb2731448887080e0c33c35c5d',
    EXCHANGE_ORDER_SERVICEABILITY:
      'https://returnrabbit.notion.site/Additional-Features-92e358a8cbcd4313a169729af7cfce68?pvs=4#682cb13b4a4b45649d4d4404e58ccca0',
    AUTO_APPROVE_WHEN_BACK_IN_STOCK:
      'https://returnrabbit.notion.site/Additional-Features-92e358a8cbcd4313a169729af7cfce68?pvs=4#b9fbbcca1e8147dba2a6c7225044b027',
    INTERNATIONAL_ORDERS_SERVICEABILITY:
      'https://returnrabbit.notion.site/Additional-Features-92e358a8cbcd4313a169729af7cfce68?pvs=4#c72c4b5e33484dd6b83ea32bcf398ee7'
  },

  SETTINGS: {
    USER_MANAGEMENT:
      'https://returnrabbit.notion.site/Manage-Users-b5bdc42004864c9b9b247ae3f9b080f5',
    MANAGE_API_TOKENS:
      'https://returnrabbit.notion.site/6e4c4222ef384309ba6e22adf6ec0802?v=259cd1bb07ad40d2ba8c9f5db06722c7'
  },

  // TRACK RABBIT -----------------------------------------------------------------------
  TRACK_RABBIT_DOC:
    'https://returnrabbit.notion.site/returnrabbit/Tracking-Rabbit-Public-Wiki-20ce3a5eb0a94a4699f11dc9f93def3d',

  TRACK_RABBIT_ORDERS:
    'https://returnrabbit.notion.site/Order-Details-11c1b0dbf8cd45028bdaf1899d200245',

  TRACK_RABBIT_TRACKING_PAGE:
    'https://returnrabbit.notion.site/Tracking-Page-fe8a1b5a62df4ab198c3ec3b62d8d051',

  TRACK_RABBIT_EMAILS:
    'https://returnrabbit.notion.site/E-mail-Templates-6b96e1e59f884d87a5204d619dadaef2',

  TRACK_RABBIT_SUBSCRIPTION:
    'https://returnrabbit.notion.site/Manage-Subscriptions-b342d550b41d446da9f7e8fe2d1e1e4e',

  TRACK_RABBIT_USERS:
    'https://returnrabbit.notion.site/Manage-Users-676611f6ad164b308b0543b56f43253f'
};
