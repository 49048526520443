import React from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import styles from './index.module.scss';

export default function Back({ to = '', show = false, children = 'Back' }) {
  const { push } = useHistory();

  return (
    <button
      onClick={() => push(to)}
      className={cn(styles.container, { [styles.show]: show && !!to })}
    >
      <i className="fas fa-arrow-left" />
      <div>{children}</div>
    </button>
  );
}
