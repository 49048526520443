import React, { useEffect, useMemo, useRef } from 'react';
import { useLocation, matchPath, generatePath } from 'react-router-dom';
import cn from 'classnames';
import _get from 'lodash/get';
import _repeat from 'lodash/repeat';
import styles from './index.module.scss';

import { UiKit } from 'components';
import Back from './Back';
import Step from './Step';

/**
 * Timeline Step schema
 * @typedef {Object} Step
 * @property {string} id
 * @property {string} title
 * @property {string} path
 */
/**
 * Timeline Props
 * @typedef {Object} Props
 * @property {Step[]} steps
 * @property {'1fr' | `${number}px`} stepWidth
 * @property {number} stepGap
 * @property {string} className
 */

/**
 * Timeline component to show the steps in the process and indicate the progress made in the process
 * @param {Props} props
 */

export default function EventTimeline({
  steps = [],
  stepWidth = '1fr',
  stepGap = 0,
  showBack = true,
  pathParams = {},
  className = ''
}) {
  const { pathname } = useLocation();
  const contentRef = useRef();
  const progressRef = useRef();

  useEffect(() => {
    const container = contentRef.current;
    const progressTrack = progressRef.current;
    if (container && progressTrack) {
      const { width } = container.getBoundingClientRect();
      const gapLength = Math.max(stepGap * (steps.length - 1), 0);
      const stepWidth = (width - gapLength) / steps.length;
      progressTrack.style.width = `${width - stepWidth}px`;
    }
  }, [steps, stepGap]);

  const templateColumns = useMemo(() => {
    return _repeat(`${stepWidth} `, steps.length);
  }, [steps, stepWidth]);

  const { activeIndex, prevStepPath } = useMemo(() => {
    const activeIndex = steps.findIndex(step => {
      return !!matchPath(pathname, { path: step.path, exact: true });
    });
    const prevStepRoute = _get(steps, `[${activeIndex - 1}].path`, '');
    const prevStepPath = generatePath(prevStepRoute, pathParams);
    return { activeIndex, prevStepPath };
  }, [steps, pathname, pathParams]);

  const { stepsList, progress } = useMemo(() => {
    return steps.reduce(
      (acc, step, index) => {
        acc.stepsList.push(
          <Step
            key={step.id}
            title={step.title}
            isActive={index === activeIndex}
            completed={index < activeIndex}
          />
        );
        acc.progress =
          index === activeIndex
            ? index / Math.max(steps.length - 1, 1)
            : acc.progress;
        return acc;
      },
      { stepsList: [], progress: 0 }
    );
  }, [steps, activeIndex]);

  return (
    <div className={cn(styles.container, className)}>
      <Back to={prevStepPath} show={showBack} />
      <div className={styles.content} ref={contentRef}>
        <UiKit.Progress
          value={progress}
          ref={progressRef}
          className={styles.progress}
        />
        <UiKit.GridRow
          gap={stepGap}
          templateColumns={templateColumns}
          className={styles.steps}
        >
          {stepsList}
        </UiKit.GridRow>
      </div>
    </div>
  );
}
