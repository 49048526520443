import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import cn from 'classnames';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import styles from './index.module.scss';

import { If } from 'utils';
import Store from '../Store';
import Loader from './Loader';
import { PageErrorUi, UiKit } from 'components';
import { useAddOnStoreConnect } from '../../hooks';
import ZeroState from '../ZeroState';

export default function Connect() {
  const { push } = useHistory();
  const { sellerId } = useParams();

  const {
    stores,
    isError,
    isLoading,
    isConnecting,
    connectingStore,
    onConnect,
    onReload
  } = useAddOnStoreConnect(sellerId);

  const supportedStores = _get(stores, 'supportedStores', []);
  const unSupportedStores = _get(stores, 'unSupportedStores', []);
  const hasStores = !_isEmpty([...supportedStores, ...unSupportedStores]);

  const eligibleStores = supportedStores.map(store => {
    const storeUrl = _get(store, 'store_url', '');
    return (
      <Store
        key={storeUrl}
        data={store}
        onConnect={onConnect}
        isConnecting={isConnecting}
        connectingStore={connectingStore}
      />
    );
  });

  const ineligibleStores = unSupportedStores.map(store => {
    const storeUrl = _get(store, 'store_url', '');
    return <Store key={storeUrl} data={store} />;
  });

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3>Available Stores via ShipStation</h3>
        <p>You can also connect additional stores later in settings.</p>
      </div>
      <PageErrorUi
        hideImage
        isError={isError}
        onReload={() => onReload()}
        msg="Oops! We failed to load the stores list! Please retry in sometime."
        wrapperClassName={styles.errorWrapper}
        contentClassName={styles.errorContent}
      >
        <Loader isLoading={isLoading}>
          <If test={hasStores} otherwise={<ZeroState />}>
            <div className={styles.stores}>{eligibleStores}</div>
            <If test={!_isEmpty(ineligibleStores)}>
              <div className={styles.unSupportedStores}>
                <div className={styles.unSupportedStoresHeader}>
                  <p>Unsupported Stores on Return Rabbit</p>
                </div>
                <div
                  className={cn(styles.stores, styles.unSupportedStoresContent)}
                >
                  {ineligibleStores}
                </div>
              </div>
            </If>
          </If>
          <UiKit.Button
            disabled={false}
            onClick={() => push('/connect-store')}
            className={styles.button}
            wrapperClassName={styles.buttonWrapper}
          >
            {hasStores ? 'Add another store' : 'Connect a store'}
          </UiKit.Button>
        </Loader>
      </PageErrorUi>
    </div>
  );
}
